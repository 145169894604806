/**
 * Slideshow animation for header images, will only load on main shortapp page (not subdirectories)
 */
if (window.location.pathname.split("/").filter(path => path).length == 1) {
    // variable to keep track of whether viewport is desktop or not
    let isDesktop = window.innerWidth >= 768;
    
    /**
     * Setup slideshow animation after html (excluding css) is loaded
     */
    document.addEventListener('DOMContentLoaded', () => {
        // since elements are rendered from the top down, 
        // the 2nd, 3th, etc images are not included in the html so that
        // the browser would immediately load the 1st image
        const SLIDESHOW_CONTAINER = document.getElementById('slideshow-container');
    
        // Handle whether the first image should be the mobile version or not
        const FIRST_IMAGE = document.getElementById('banner-img');
        if (!isDesktop) {
            FIRST_IMAGE.src = "https://www.star.hawaii.edu/cdn/images/shortapp/landing/2/Headerhomepage-1-mobile.png";
        }

        slideshow(SLIDESHOW_CONTAINER);
    });
    
    const TIMER_ID_TRACKER = {};

    /**
     * Create the slideshow used by the welcome images
     */
    function slideshow(container) {
        const ANIMATION_ELEMENTS = Array.from(container.getElementsByClassName('image-content')).reverse();
    
        /**
         * add css class that could trigger animation
         */
        const animate = (element, cssClass, waitMS) => setTimeout(() => element.classList.add(cssClass), waitMS);

        /**
         * remove css class, this will also trigger animation due to the transition css rule
         */
        const reverseAnimate = (element, cssClass, waitMS) => setTimeout(() => element.classList.remove(cssClass), waitMS);
    
        /**
         * Creates the timing between the different transition points, the order
         * in which the element are passed matters
         */
        const scheduleKeyframes = (elements) => {
            const WAIT_TIME = 13000;
            const NEXT_STEP_TIME = 300;
            let currentDuration = 0;
    
            elements.forEach((element, index) => {
                currentDuration += WAIT_TIME;
                animate(element, 'fade-out', currentDuration);
    
                const NEXT_INDEX = (index + 1) % elements.length;
                const REDUCE_WAIT = NEXT_INDEX == 0 ? 0 : 1000;
                reverseAnimate(elements[NEXT_INDEX], 'fade-out', currentDuration - REDUCE_WAIT);
    
                currentDuration += NEXT_STEP_TIME;
            });
    
            return currentDuration;
        };
    
        // animation time table should look like this:
        // |---13s---[first image fades out]
        //           [second image fades in abd becomes visible]---13s---[second image fades out]
        //                                                                [first image fades in]
        const ANIMATION_DURATION = scheduleKeyframes(ANIMATION_ELEMENTS);
        setInterval(() => scheduleKeyframes(ANIMATION_ELEMENTS), ANIMATION_DURATION);
    
        /**
         * Change the image source based on viewport size if needed
         * Don't do transitions during resize to reduce the jank that appears at that moment
         */
        window.addEventListener("resize", () => {
            if (window.innerWidth < 768 && isDesktop) {
                ANIMATION_ELEMENTS.forEach((element, index) => {
                    element.src = `https://www.star.hawaii.edu/cdn/images/shortapp/landing/2/Headerhomepage-${index + 1}-mobile.png`
                });
                isDesktop = false;
            }
            else if (window.innerWidth >= 768 && !isDesktop) {
                ANIMATION_ELEMENTS.forEach((element, index) => {
                    element.src = `https://www.star.hawaii.edu/cdn/images/shortapp/landing/2/Headerhomepage-${index + 1}.png`
                });
                isDesktop = true;
            }

            ANIMATION_ELEMENTS.forEach((element) => {
                element.classList.add("disable-transition-animation");
                clearTimeout(TIMER_ID_TRACKER[element.id]);
                TIMER_ID_TRACKER[element.id] = setTimeout(() => element.classList.remove("disable-transition-animation"), 400);
            });
        });
    }
}